import React from "react"

import { scroller } from "react-scroll"

function NavbarComponent() {
  return (
    <div
      className={
        "sticky top-2 flex justify-center items-center justify-items-center z-50 navbar--wrapper"
      }
    >
      <nav
        className={
          "inline-flex bg-white rounded-full shadow-2xl justify-center text-lg"
        }
        role="group"
      >
        <button
          onClick={() =>
            scroller.scrollTo("about-me", {
              duration: 500,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -65,
            })
          }
          className="px-1 py-2 pl-2 mx-0 text-xs leading-tight text-gray-600 uppercase bg-white rounded-l-full outline-none font-title sm:font-normal sm:pl-3 sm:shadow-none sm:px-2 sm:tracking-tight dark:bg-gray-600 dark:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800 sm:py-1 sm:text-xl focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline"
        >
          About me
        </button>
        <button
          onClick={() =>
            scroller.scrollTo("sustainability", {
              duration: 500,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -65,
            })
          }
          className="px-1 py-2 mx-0 text-xs leading-tight text-gray-600 uppercase bg-white outline-none font-title sm:font-normal sm:shadow-none sm:px-2 sm:tracking-tight dark:bg-gray-600 dark:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800 sm:py-1 sm:text-xl focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline"
        >
          Sustainability
        </button>
        <button
          onClick={() =>
            scroller.scrollTo("process", {
              duration: 500,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -65,
            })
          }
          className="px-1 py-2 mx-0 text-xs leading-tight text-gray-600 uppercase bg-white outline-none font-title sm:font-normal sm:shadow-none sm:px-2 sm:tracking-tight dark:bg-gray-600 dark:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800 sm:py-1 sm:text-xl focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline"
        >
          Process
        </button>
        <button
          onClick={() =>
            scroller.scrollTo("gallery", {
              duration: 500,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -65,
            })
          }
          className="px-1 py-2 mx-0 text-xs leading-tight text-gray-600 uppercase bg-white outline-none font-title sm:font-normal sm:shadow-none sm:px-2 sm:tracking-tight dark:bg-gray-600 dark:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800 sm:py-1 sm:text-xl focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline"
        >
          Gallery
        </button>
        <button
          onClick={() =>
            scroller.scrollTo("contact", {
              duration: 500,
              delay: 0,
              smooth: "easeInOutQuart",
              offset: -65,
            })
          }
          className="px-1 py-2 pr-2 mx-0 text-xs leading-tight text-gray-600 uppercase bg-white rounded-r-full outline-none font-title sm:font-normal sm:pr-3 sm:shadow-none sm:px-2 sm:tracking-tight dark:bg-gray-600 dark:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-800 sm:py-1 sm:text-xl focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline"
        >
          Contact
        </button>
      </nav>
    </div>
  )
}

export default NavbarComponent
