import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Lightbox from "lightbox-react"
import { useStaticQuery, graphql } from "gatsby"

import "../css/gallery.css"

const GalleryComponent = ({ data, sectionId }) => {
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const highresImages = useStaticQuery(graphql`
    query highresImgs {
      allContentfulSections {
        edges {
          node {
            id
            gallery {
              image {
                gatsbyImageData(width: 1050, quality: 50, placeholder: BLURRED)
              }
              alt
              caption {
                caption
              }
            }
          }
        }
      }
    }
  `)

  const allImages = highresImages.allContentfulSections.edges.filter(
    el => el.node.id === sectionId
  )[0].node.gallery

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 100) {
      // do your stuff here for left swipe
      setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)
    }

    if (touchStart - touchEnd < -100) {
      // do your stuff here for right swipe
      setLightboxIndex(
        (lightboxIndex + lightboxImages.length - 1) % lightboxImages.length
      )
    }
  }

  const imgArray = data
  const amountOfImages = imgArray.length

  const images = imgArray.map((node, i) => {
    let imageClassNames =
      "inline-flex rounded-lg h-auto overflow-hidden gradient-mask-t-0% sm:shadow-lg cursor-pointer hover:shadow-xl dark:text-gray-50"

    if (amountOfImages === 1) {
      imageClassNames = `${imageClassNames} col-start-1 mx-auto sm:col-start-1 md:col-start-1 md:mx-24 lg:col-start-2 lg:-mx-20`
    }

    if (amountOfImages === 3) {
      imageClassNames = `${imageClassNames} col-start-0 sm:col-start-0 md:col-start-0 lg:col-start-0`
    }

    if (amountOfImages === 2 && i === 0) {
      imageClassNames = `${imageClassNames} col-start-0 sm:col-start-2 md:col-start-2 lg:col-start-3`
    }

    return (
      <div
        className={`${imageClassNames}`}
        onClick={() => {
          setLightboxIndex(i)
          setIsOpen(true)
        }}
        aria-hidden="true"
        key={`gallery-image-${i}`}
      >
        <GatsbyImage
          imgClassName={"rounded-lg"}
          image={getImage(node.image)}
          objectFit={"cover"}
          loading={"eager"}
          key={`image-grid-photo-${i}`}
          alt={node.alt || "No image alt text available."}
          title={node.alt || "No image title available."}
        />
      </div>
    )
  })

  const lightboxImages = allImages.map((node, i) => {
    return (
      <div
        className="justify-center w-screen h-screen max-w-full md:pb-8 md:max-h-9/10 lg:max-h-19/20"
        key={`lightbox-image-${i}`}
      >
        <GatsbyImage
          onTouchEnd={handleTouchEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          image={node.image.gatsbyImageData}
          key={`lightbox-image-grid-photo-${i}`}
          className="w-full h-full"
          layout={"fullWidth"}
          alt={node.alt || "No image alt text available."}
          // title={node.alt || "No image title available."}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </div>
    )
  })

  const lightboxCaptions = imgArray.map(node => {
    return (
      <p className="inline w-auto px-2 py-1 m-0 text-xs tracking-tight text-center bg-black rounded leading-tighter sm:leading-tight sm:text-lg lg:text-lg text-gray-50 font-body decoration-clone caption">
        {node.caption.caption}
      </p>
    )
  })

  const [lightboxIndex, setLightboxIndex] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  let containerClassNames = "grid gap-4 mx-auto mt-4 mb-8 md:-mx-0 lg:-mx-48"
  if (amountOfImages === 1) {
    containerClassNames = `${containerClassNames} grid-cols-1 sm:grid-cols-1 md:grid-cold-3 lg:grid-cols-3`
  }
  if (amountOfImages === 2) {
    containerClassNames = `${containerClassNames} grid-cols-2 sm:grid-cols-4 md:grid-cold-5 lg:grid-cols-4`
  }
  if (amountOfImages === 3) {
    containerClassNames = `${containerClassNames} grid-cols-3 sm:grid-cols-3 md:grid-cold-3 lg:grid-cols-3`
  }
  if (amountOfImages > 3) {
    containerClassNames = `${containerClassNames} grid-cols-3 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-7`
  }

  return (
    <>
      <div className={`${containerClassNames}`}>{images}</div>
      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[lightboxIndex]}
          nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[
              (lightboxIndex + lightboxImages.length - 1) %
                lightboxImages.length
            ]
          }
          // animationDuration={500}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + lightboxImages.length - 1) %
                lightboxImages.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)
          }
          enableZoom={false}
          imageCaption={lightboxCaptions[lightboxIndex]}
        />
      )}
    </>
  )
}

export default GalleryComponent
