import React from "react"

import { scroller } from "react-scroll"

function ChevronDown() {
  return (
    <button
      className={
        "w-16 absolute bottom-0 animate-bounce cursor-pointer text-yellow-400"
      }
      onClick={() =>
        scroller.scrollTo("about-me", {
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -65
        })
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        className={"fill-current"}
        viewBox="0 0 100 100"
      >
        <path
          fillRule="evenodd"
          d="M29.375 37c-1-.875-2.5-.875-3.5 0-1 1-1 2.625 0 3.625L48.25 63c1 .875 2.5.875 3.5 0l22.375-22.375c1-1 1-2.625 0-3.625-1-.875-2.5-.875-3.5 0L50 57.625z"
        ></path>
      </svg>
    </button>
  )
}

export default ChevronDown
