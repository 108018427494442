import React, { useState } from "react"

import { graphql } from "gatsby"
import { Waypoint } from "react-waypoint"

import LayoutComponent from "../components/layout"
import SectionComponent from "../components/section"
import NavbarCompoinent from "../components/navbar"
import ChevronDown from "../components/icons"
import SocialIconsComponent from "../components/socialIcons"
import HomepageLogoComponent from "../components/homepageLogo"
import HomepageTitleComponent from "../components/homepageTitle"

import { SITE_TITLE, SITE_SUBTITLE } from "../utils/siteConfig"

const IndexPage = props => {
  const sortedSections = props.data.allContentfulSections.edges.sort(
    (a, b) => a.node.pageOrder - b.node.pageOrder
  )
  const [showScrollAnimation, setShowScrollAnimation] = useState(false)

  return (
    <LayoutComponent pageTitle={"Bespoke Wood Craft"}>
      <div
        className={
          "w-full flex-col h-screen h-screen-ios inset-0 items-center p-4 flex justify-center bg-gradient-to-b from-yellow-400 via-yellow-500 to-gray-50 dark:to-gray-800"
        }
      >
        <HomepageLogoComponent />
        <HomepageTitleComponent title={SITE_TITLE} subtitle={SITE_SUBTITLE} />

        <Waypoint
          onEnter={() => setShowScrollAnimation(true)}
          onLeave={() => setShowScrollAnimation(false)}
        />

        {/* <SocialIconsComponent /> */}

        {showScrollAnimation && <ChevronDown />}
      </div>

      <NavbarCompoinent />

      {sortedSections.map(el => {
        return (
          <SectionComponent content={el.node} key={`section-${el.node.slug}`} />
        )
      })}

      <div
        className={
          "w-full flex-col h-screen inset-0 items-center p-4 flex justify-center bg-gradient-to-t from-yellow-400 via-yellow-500 to-gray-50 dark:to-gray-800"
        }
      >
        <HomepageLogoComponent />
        <HomepageTitleComponent title={SITE_TITLE} subtitle={SITE_SUBTITLE} />
        <SocialIconsComponent />
      </div>
    </LayoutComponent>
  )
}

export const query = graphql`
  query {
    allContentfulSections {
      edges {
        node {
          id
          title
          subtitle
          slug
          pageOrder
          showMap
          emoji
          contactForm
          text {
            raw
          }
          gallery {
            image {
              gatsbyImageData(width: 500, quality: 50, placeholder: TRACED_SVG)
            }
            alt
            caption {
              caption
            }
          }
          sectionIcon {
            gatsbyImageData(width: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default IndexPage
