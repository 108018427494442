import React from "react"

const MapComponent = props => {
  return (
    <div className={'w-full h-0 relative pt-full md:pt-twothirds'}>
      <iframe
        className={'w-full h-full absolute top-0 left-0'}
        title={"Jimbo's workshop"}
        src="https://www.google.com/maps/d/u/0/embed?mid=1TCTi8hqBDmAdUaqBjkxoG4oPR6A_ic1N"
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default MapComponent
