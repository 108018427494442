import React from "react"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const website_url = "https://www.jimbowoodsmith.co.za/"

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    // [INLINES.HYPERLINK]: link => (
    //   <a target={link.target} rel="noopener noreferrer" href={link.href}>
    //     {link.content}
    //   </a>
    // ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        href={data.uri}
        target={`${data.uri.startsWith(website_url) ? "_self" : "_blank"}`}
        rel={`${data.uri.startsWith(website_url) ? "" : "noopener noreferrer"}`}
      >
        {children}
      </a>
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li style={{listStyle: 'none'}}>{children}</li>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    },
  },
}

function RichTextComponent({ data }) {
  return <div>{data && renderRichText(data, options)}</div>
}

export default RichTextComponent
