import * as React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import ContactFormComponent from "../components/contactForm"
import GalleryComponent from "../components/gallery"
import MapComponent from "../components/map"
import RichTextComponent from "../components/richText"

function SectionComponent(props) {
  const {
    id,
    title,
    subtitle,
    text,
    slug,
    gallery,
    sectionIcon,
  } = props.content

  const hasGallery = props.content.gallery || false
  const hasContactForm = props.content.contactForm
  const hasMap = props.content.showMap

  return (
    <section
      className={"my-20 max-w-2xl ml-auto mr-auto px-4"}
      key={id}
      id={slug}
      name={slug}
    >
      <div className={"flex justify-center flex-col items-center"}>
        <GatsbyImage
          className={
            "rounded-full border-yellow-400 bg-gradient-to-br from-bg-gray-50 via-white to-gray-100 border-4 w-24 h-24 flex items-center shadow-md dark:bg-gray-600 justify-center align-center"
          }
          imgClassName={'flex self-center justify-self-center w-12'}
          objectFit={'cover'}
          imgStyle={{
            width: '65%',
            height: '65%',
            objectPosition: 'center',
            margin: 'auto'
          }}
          image={sectionIcon.gatsbyImageData}
          alt={"Section header icon"}
        />
        <div className={"text-left w-full"}>
          <div className={"text-center"}>
            <h2
              className={
                "rounded-full tracking-tight leading-tight shadow-sm mt-3 bg-yellow-400 inline-block px-3 font-title text-base sm:text-lg md:text-xl mb-0 pb-2 pt-2 dark:text-gray-800"
              }
            >
              {title}
            </h2>
            <div>
              <h3
                className={
                  "text-sm sm:text-base md:text-base px-3 tracking-tight leading-tight mt-3 mb-6 bg-gray-300 inline-block rounded-full shadow-sm md:mb-5 md:px-3 py-1 pb-1 pt-1 dark:bg-gray-500"
                }
              >
                {subtitle}
              </h3>
            </div>
          </div>
          <div className={"container tracking-normal leading-relaxed"}>
            <RichTextComponent data={text} />
          </div>
        </div>
        {hasGallery && <GalleryComponent data={gallery} sectionId={id} />}
        {hasContactForm && <ContactFormComponent />}
        {hasMap && <MapComponent />}
      </div>
    </section>
  )
}

export default SectionComponent
