import React, { useState } from "react"
import { useForm } from "react-hook-form"

const ContactFormComponent = props => {
  const { register, handleSubmit } = useForm()
  const [messageSent, setMessageSent] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...data }),
    })
      // .then(() => alert("Success!"))
      .then(() => setMessageSent(true))
      .catch(error => alert(error))
  }

  return (
    <div className={'w-full -mt-2'}>
      {/* <h3>Drop me a message:</h3> */}
      {messageSent ? (
        <div className="p-4 text-center border-4 border-green-300 rounded-lg">
          <h3 className="p-0 m-0">Your message was successfully sent!</h3>
          <p className="p-0 m-0">
            Thanks for gettingh in touch. I'll get back to you as soon as
            possible.
          </p>
        </div>
      ) : (
        <form
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          id="contact-form"
          className="w-full mx-auto mt-2 bg-transparent"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" name="form-name" value="contact-form" />

          <input type="hidden" name="bot-field" value="" />

          <div className="flex flex-wrap pt-2 mb-6">
            <div className="relative w-full appearance-none label-floating">
              <input
                className="block w-full px-4 py-2 mb-0 leading-relaxed tracking-normal bg-gray-200 border border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="name"
                type="text"
                placeholder="Your name"
                required
                name="name"
                ref={register}
              />
              <label
                htmlFor="name"
                className="absolute top-0 left-0 block px-4 py-2 mb-4 leading-tight tracking-normal opacity-0 cursor-text"
              >
                Your name
              </label>
            </div>
          </div>

          <div className="flex flex-wrap mb-6">
            <div className="relative w-full appearance-none label-floating">
              <input
                className="block w-full px-4 py-2 mb-0 leading-relaxed tracking-normal bg-gray-200 border border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                placeholder="Your email"
                required
                ref={register}
                name="email"
              />
              <label
                htmlFor="email"
                className="absolute top-0 left-0 block px-4 py-2 mb-4 leading-tight tracking-normal opacity-0 cursor-text"
              >
                Your email
              </label>
            </div>
          </div>

          <div className="flex flex-wrap mb-6">
            <div className="relative w-full appearance-none label-floating">
              <input
                className="block w-full px-4 py-2 mb-0 leading-relaxed tracking-normal bg-gray-200 border border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="phone"
                type="number"
                placeholder="Your phone number"
                ref={register}
                name="phone"
                pattern="\d*"
              />
              <label
                htmlFor="phone"
                className="absolute top-0 left-0 block px-4 py-2 mb-4 leading-tight tracking-normal opacity-0 cursor-text"
              >
                Your phone number
              </label>
            </div>
          </div>

          <div className="flex flex-wrap mt-8 mb-4">
            <div className="relative w-full appearance-none label-floating">
              <textarea
                rows="5"
                className="block w-full px-4 py-2 mb-0 leading-relaxed tracking-normal bg-gray-200 border border-gray-200 rounded-lg appearance-none autoexpand focus:outline-none focus:bg-white focus:border-gray-500"
                id="message"
                type="text"
                placeholder="Your message..."
                ref={register}
                name="message"
              />
              <label
                htmlFor="message"
                className="absolute top-0 left-0 block px-4 py-2 mb-4 leading-tight tracking-normal opacity-0 cursor-text"
              >
                Your message...
              </label>
            </div>
          </div>

          <div>
            <button
              className="w-full px-4 py-2 mt-2 font-bold bg-yellow-400 rounded-lg shadow hover:bg-yellow-300 dark:text-gray-800 focus:shadow-lg focus:outline-none"
              type="submit"
            >
              Send
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default ContactFormComponent
